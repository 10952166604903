import React, {useEffect, useRef} from "react";
import {Spacer, Text} from "@dspworkplace/ui";
import {BigTitle, Container, Loading} from "../../Components/UI";
import {logo} from "../../assets";
import {Image} from "../../Components/Menus/Main";

const Preparing = props => {
    const {email, password} = props.location.state || {};

    useEffect(() => {
        if (email && password)
            setInterval(() => {
                fetch(
                    `${process.env.REACT_APP_API_ENDPOINT}/api/login_check`,
                    {
                        method: 'post',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            username: email,
                            password: password,
                            isCorporate: false,
                            timezone: false
                        })
                    }
                ).then(
                    result => result.status === 200 && (window.location.href = process.env.REACT_APP_BASE_URL)
                )
            }, 15000);
    }, [email, password]);

    return (
        <Container>
            <Spacer all={10} style={{textAlign: 'center'}}>
                <Image src={logo} alt="dspworkplace"/>
                <Spacer top={20}/>
                <BigTitle>We are preparing your account. It can take several minutes – we have a lot to do!</BigTitle>
                <Spacer bottom={5}/>
                <Text>We will notify you by email when our process is complete, otherwise, sit tight and we will redirect you as soon as your account is ready for use.</Text>
                <Spacer top={20}>
                    <Loading/>
                </Spacer>
            </Spacer>
        </Container>
    )
}

export default Preparing;