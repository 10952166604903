import React from "react";
import {Container as GridContainer, setConfiguration} from "react-grid-system";
import styled from "styled-components";
import {Spacer, Theme, Title} from "@dspworkplace/ui";

import {ReactComponent as LoadingSVG} from '../assets/dsp-loading.svg';

export const Container = props => {
    setConfiguration({
        containerWidths: [540, 750, 960, 1240, 1540]
    })

    return <GridContainer {...props}>
        {props.children}
    </GridContainer>
}

export const BigTitle = styled(Title)`
    font-size: 52px;
    line-height: 52px;
`;

export const LightBlueSection = styled(Spacer).attrs(props => ({
    top: 15,
    bottom: 15,
    left: 10,
    right: 10,
}))`
    background: ${Theme.colors.action}33;
`;

export const Divider = styled('hr')`
    border: 0;
    width: ${props => props.full ? 100 : 50}%;
    margin: auto;
    height: 1px;
    background: ${Theme.colors.extra.whiteSmoke};
`;

export const Card = styled.div`
    padding:20px;
    background: ${Theme.colors.neutrals.white};
    box-shadow: 0 3px 6px ${Theme.colors.neutrals.gray}26;
    border-radius: ${Theme.defaultRadius};
`;

export const Skeleton = styled.div.attrs({
    className:'skeleton'
})`
    display: inline-block;
    vertical-align: middle;
    height: ${props => props.height ? `${props.height}px` : '100%'};
    width: ${props => props.width ? `${props.width}px` : '100%'};
    
    background: linear-gradient(-90deg, #F0F0F0 0%, #F8F8F8 50%, #F0F0F0 100%);
    background-size: 400% 400%;
    animation: ${props => props.animation === false ? 'none' : 'pulse 1.2s ease-in-out infinite'};
    
    @keyframes pulse {
        0% {
            background-position: 0% 0%;
        }
        100% {
            background-position: -135% 0%;
        }
    }
`;

export const LoadingStyles = styled.span`
    display: inline-block;

    svg {
        display: block;
    }
    
    svg path {
        fill: inherit;
    }
`;

export const Loading = ({width = 40}) => {
    return (
        <LoadingStyles>
            <LoadingSVG width={width}/>
        </LoadingStyles>
    )
};