import React from "react";
import { Col, Row, Visible } from "react-grid-system";
import styled from 'styled-components';
import { Button, Spacer, SubTitle, Text, Theme } from "@dspworkplace/ui";
import * as assets from "../../assets";
import { Container } from "../UI";
import { Link } from "react-router-dom";


import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

const Quote = styled.blockquote`
    font-family: ${Theme.font.main};
    font-size: 24px;
    color: ${Theme.colors.secondary};
    margin: 0;
    position: relative;
    text-align:left;
    margin-top:15px;

    @media only screen and  (max-width:1583px){font-size: 20px;}
    @media only screen and  (max-width:1182px){font-size: 24px;}
    @media only screen and  (max-width:750px){font-size: 20px;}
    
    &::before, 
    &:after {
        content: '';
        display: block;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        width: 60px;
        height: 40px;
        pointer-events: none;
        position: absolute;
        
        background-image: url(${assets.quoteOpen});
        top: -16px;
        
    }
    
    &::after {
        background-image: url(${assets.quoteClose});
        top: auto;
        left: auto
        bottom: 0;
        right: 0;
    }
`;

const Testimonials = () => {
    return (
        <Container>
            <Row>
                <Col md={12} lg={12} xl={5}>
                    <Spacer left={5} right={5}>
                        <Visible lg xl xxl>
                            <Spacer top={30} />
                        </Visible>
                        <Visible xs sm md>
                            <Spacer top={5} />
                        </Visible>

                        <Carousel autoPlay={true} interval={5000} infiniteLoop={true} showArrows={true} showStatus={false} showIndicators={false}>
                            <>
                                <Quote>
                                    Profitability is largely about controlling costs and dspworkplace provides real-time insight into weekly hours worked per employee, vehicle damage and maintenance tracking, and invoice reconciliation, replacing the need to purchase these modules from multiple, separate vendors. dspworkplace addresses these issues better than any other tool available
                                </Quote>
                                <Spacer bottom={3} />
                                <Text>Upper Midwest DSP</Text>
                            </>
                            <>
                                <Quote>
                                    dspworkplace has allowed our team to consolidate hours of work reconciling spreadsheets each day by bringing these processes into one web application!
                                </Quote>
                                <Spacer bottom={3} />
                                <Text>Midwest DSP</Text>
                            </>
                            <>
                                <Quote>
                                    dspworkplace has helped me consolidate a lot of the spreadsheets, apps, and systems I was using into one website. My operations team loves the morning automation, and the data collection; and the scorecard features has been a tremendous help to my team. I highly recommend the program if you're looking to run more efficiently as a company, which drives profitability
                                </Quote>
                                <Spacer bottom={3} />
                                <Text>Southern DSP</Text>
                            </>
                            <>
                                <Quote>
                                    Your app helped me earn my company's first Fantastic+...
                                </Quote>
                                <Spacer bottom={3} />
                                <Text>South Central DSP</Text>
                            </>
                        </Carousel>
                        <Visible  xxl>
                            <Spacer bottom={1} />
                            {/* <Text>City ST</Text> */}
                            <Spacer bottom={10} />
                        </Visible>
                        <SubTitle>Join others now and start your 30 days free trial</SubTitle>
                        <Spacer bottom={3} />
                        <Button as={Link} to='/register' type='primary'>Start my 30 days free trial</Button>
                    </Spacer>
                </Col>

                <Visible xl xxl>
                    <Col lg={6} offset={{ lg: 1 }}>
                        <Visible xs sm md>
                            <Spacer top={10} />
                        </Visible>
                        <img src={assets.testimonial} alt="" width='100%' style={{ display: 'block' }} />
                    </Col>
                </Visible>
            </Row>
        </Container>
    )
}

export default Testimonials;