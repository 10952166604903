import React from 'react';
import {BrowserRouter, Redirect, Route, Switch} from 'react-router-dom';
import {ScreenClassProvider} from 'react-grid-system';

import DefaultLayout from './Layouts/Default';
import BlankLayout from "./Layouts/Blank";

import Home from '../Pages/Home';
import Features from '../Pages/Features';
import Pricing from '../Pages/Pricing';
import PricingWordpress from '../Pages/Pricing/wordpress';
import Contact from '../Pages/Contact';
import Register from '../Pages/Register';
import Preparing from '../Pages/Register/preparing';
import Terms from '../Pages/Terms';
import Privacy from '../Pages/Privacy';
import NotFound from '../Pages/404';
import VegasBaby from "../Pages/VegasBaby";

const routes = [
    // {
    //     name: "Home",
    //     path: "/",
    //     exact: true,
    //     component: Home
    // },
    // {
    //     name: "Features",
    //     path: "/features",
    //     component: Features
    // },
    {
        name: "Pricing Wordpress",
        path: "/pricing_wordpress",
        layout: BlankLayout,
        component: PricingWordpress,
        useMenu: false,
    },
    // {
    //     name: "Pricing",
    //     path: "/pricing",
    //     component: Pricing
    // },
    /*{
        name: "About",
        path: "/about",
        component: (()=>(<div>I'm About</div>))
    },*/
    // {
    //     name: "Contact",
    //     path: "/contact",
    //     component: Contact
    // },
    {
        name: "Start 30 Days Free Trial",
        path: "/register",
        layout: BlankLayout,
        component: Register,
        useMenu: false
    },
    {
        name: "Preparing your account",
        path: "/preparing",
        layout: BlankLayout,
        component: Preparing,
        useMenu: false
    },
    // {
    //     name: "Terms And Condition",
    //     path: "/terms-and-conditions",
    //     component: Terms,
    //     useMenu: false,
    // },
    // {
    //     name: "Privacy",
    //     path: "/privacy",
    //     component: Privacy,
    //     useMenu: false,
    // },
    {
        name: "DMGgo and dspworkplace: Announcing a new strategic partnership!",
        path: "/dmggo-partnership",
        layout: BlankLayout,
        component: VegasBaby,
        useMenu: false,
    },
];

const route = () => {
    return (
        <ScreenClassProvider>
            <BrowserRouter>
                <Switch>
                    {
                        routes.map((route, i) => (
                            route.layout
                                ? React.createElement(route.layout, {key: i, ...route})
                                : <DefaultLayout key={i} {...route} />
                        ))
                    }
                    {/*<Route component={NotFound}/>*/}
                    <Redirect to={'/register'}></Redirect>
                </Switch>
            </BrowserRouter>
        </ScreenClassProvider>
    );
}

export {routes};

export default route;
