import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Col, Hidden, Row} from 'react-grid-system';
import {Button, ButtonGroup, Icon, Spacer, SubTitle, Text, Theme, ThirdTitle, Title} from '@dspworkplace/ui';
import {Card, Container, Skeleton} from "../../Components/UI";
import Range from "../../Components/Range";
import api, {isDev} from "../../api";

export const FEATURES = [
    {
        name: 'DSP scheduling application - includes shift management',
        basic: true,
        partner: true,
    },
    {
        name: 'Vehicle and device inventory tracking',
        basic: true,
        partner: true,
    },
    {
        name: 'Mobile, morning load out and RTS management workflows',
        basic: true,
        partner: true,
    },
    {
        name: 'Morning kick-off workflow for managing driver load out',
        basic: true,
        partner: true,
    },
    {
        name: 'RouteAssist&trade;',
        basic: true,
        partner: true,
    },
    {
        name: 'Fully integrated chat engine',
        basic: true,
        partner: true,
    },
    {
        name: 'Comprehensive mobile application (iOS and Android)',
        basic: true,
        partner: true,
    },
    {
        name: 'Mobile app pickup open and swap shifts',
        basic: true,
        partner: true,
    },
    {
        name: 'Payroll punch time integration',
        basic: isDev ? 'prod_KkPYQ7aFJ4pavZ' : 'prod_KwdRRqmjJ6MpBh',
        partner: true,
    },
    {
        name: 'Complete incident management workflows include: driver accident/injury forms, tier tracking, UPD tracker and driver call outs',
        basic: false,
        partner: true,
    },
    {
        name: 'Route commitment tracking',
        basic: false,
        partner: true,
    },
    {
        name: 'Schedule balance views',
        basic: false,
        partner: true,
    },
    {
        name: 'Package count information',
        basic: false,
        partner: true,
    },
    {
        name: 'Mobile app announcements: for driver updates and education',
        basic: false,
        partner: true,
    },
    {
        name: 'Build your own reports (coming soon)',
        basic: false,
        partner: true,
    },
    {
        name: 'Amazon invoice comparison tool (coming soon)',
        basic: false,
        partner: true,
    },
];


const Price = styled.span`
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    
    small {
        font-size: .5em;
        vertical-align: top;
    }
`;

export const Help = styled(Button)`
    line-height: 20px;
    height: 20px;
    width: 20px;
    padding: 0;
    font-size: 14px;
    border-radius: 100px;
    border: 1px solid ${Theme.colors.info.border};
    position: relative;
    
    &::after {
        display: block;
        content: "${props => props.text}";
        width: 94px;
        padding: 12px;
        background: ${Theme.colors.neutrals.white};
        border-radius: ${Theme.defaultRadius};
        box-shadow: 0 3px 6px ${Theme.colors.neutrals.gray}26;
        text-align: left;
        font-family: ${Theme.font.body};
        color: ${Theme.colors.neutrals.gray};
        font-weight: normal;
        position: absolute;
        top: 50%;
        left: 100%;
        transform: translate(4px, -50%);
    }
    
    &::before {
        content: "";
        display: block;
        right: -4px;
        top: 50%;
        border: solid transparent;
        height: 0;
        width: 0;
        position: absolute;
        border-color: rgba(255, 255, 255, 0);
        border-right-color: #fff;
        border-width: 8px;
        margin-top: -8px;
        z-index: 1;
    }
    
    &::before,
    &::after {
        opacity: 0;
        pointer-events: none;
        transition: all .3s;
    }
    
    &:hover,
    &:focus {
        &::before,
        &::after {
            opacity: 1;
        }
    }
`;

const App = () => {
    const [users, setUsers] = useState(60);
    const [packages, setPackages] = useState({});
    const [addons, setAddons] = useState([]);
    const [pricing, setPricing] = useState('yearly');

    const {fetchPackages, fetchAddons, abort} = api();

    const fetchData = () =>
        Promise.all([
            fetchPackages(),
            fetchAddons(),
        ]).then(([packages, addons]) => {
            setPackages(packages);
            setAddons(addons);
        });

    useEffect(() => {
        fetchData();

        const post = () => {
            const height = document.getElementById("root").scrollHeight + 50;
            window.parent.postMessage([height], "*");
        }
        setInterval(post, 100);
        post();

        return () => abort();
    }, []);

    const {basic, partner} = packages;
    if (basic && partner)
        [basic, partner].forEach(p =>
            p.unitPrice = parseFloat((p.processorData.prices
                .filter(price => price.lookup_key !== null)
                .find(price => price.recurring.interval === (pricing === 'yearly' ? 'year' : 'month'))
                .unit_amount_decimal / 100 * (pricing === 'yearly' ? (1/12) : 30)).toFixed(2))
        );

    return (
        <Container style={{textAlign: 'center'}}>
            <Spacer right={0} left={0} top={10}>
                <Row>
                    <Col md={4}>
                        <Row>
                            <Col md={10}>
                                <Card>
                                    <ThirdTitle>How many users does<br/> your company have?</ThirdTitle>
                                    <Spacer bottom={2}/>
                                    <Text>Including drivers, managers, dispatchers, operations, etc</Text>
                                    <Spacer bottom={4}/>
                                    <Range
                                        defaultValue={users}
                                        min={10}
                                        max={1000}
                                        onChange={u => setUsers(parseInt(u))}
                                        size={'extraSmall'}
                                    />
                                    <Spacer bottom={4}/>
                                    <div style={{
                                        display: 'flex',
                                        alignItems: 'baseline',
                                        justifyContent: 'center'
                                    }}>
                                        <ThirdTitle>Preferred payment term:</ThirdTitle>
                                    </div>
                                    <Spacer bottom={2}/>
                                    <div style={{
                                        display: 'flex',
                                        alignItems: 'baseline',
                                        justifyContent: 'center'
                                    }}>
                                        <ButtonGroup
                                            active={pricing}
                                            size={'small'}
                                        >
                                            <Button track={'monthly'} onClick={()=>setPricing('monthly')}>Monthly</Button>
                                            <Button track={'yearly'} onClick={()=>setPricing('yearly')}>Annually</Button>
                                        </ButtonGroup>
                                    </div>
                                    <Spacer bottom={4}/>
                                    <Text><small>You can change your number of users at any time and pricing will automatically be updated.</small></Text>
                                    {/*<Text><small>If you need more than 1000 users contact us for special price</small></Text>*/}
                                </Card>
                            </Col>
                            <Col md={2}>
                                <Hidden xs sm>
                                    <Spacer bottom={35}/>
                                    <Icon.ArrowRight color={Theme.colors.info.border}/>
                                </Hidden>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={4}>
                        <Hidden md lg xl>
                            <Spacer top={5}></Spacer>
                        </Hidden>
                        <Card style={{
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                        }}>
                            <SubTitle>Basic package</SubTitle>
                            <Spacer bottom={3}/>
                            <Text>{basic?.description || <Skeleton height={48}/>}</Text>
                            <Spacer bottom={3}/>
                            <Price style={{
                                flexDirection: 'column',
                                marginTop: 'auto'
                            }}>
                                <Title>
                                    {pricing === 'yearly' && basic?.unitPrice &&
                                        <Spacer inline right={3}>
                                            <del><small>${(basic.unitPrice + .5) * users}</small></del>
                                        </Spacer>}
                                    $
                                    {basic?.unitPrice
                                        ? basic.unitPrice * users
                                        : <Skeleton height={24} width={30}/>}
                                </Title>
                                <Spacer bottom={1}/>
                                <Text>
                                    /month
                                    <Spacer inline right={1}/>
                                    <Help text={`${users} users x $${basic?.unitPrice} per user`}>?</Help>
                                </Text>
                            </Price>
                            <Spacer bottom={3}/>
                            <Button
                                disabled={!basic?.id}
                                type='primary'
                                as={'a'}
                                href={'https://register.dspworkplace.com/register'}
                                target={'_blank'}
                                style={{display: 'block'}}
                            >Get Started</Button>
                        </Card>
                    </Col>
                    <Col md={4}>
                        <Hidden md lg xl>
                            <Spacer top={20}></Spacer>
                        </Hidden>
                        <div style={{
                            background: Theme.colors.info.bg,
                            padding: 12,
                            borderRadius: `${Theme.defaultRadius} ${Theme.defaultRadius} 0 0`,
                            marginTop: -40,
                        }}>
                            <Text style={{
                                fontSize: 12,
                                color: Theme.colors.info.text
                            }}>Best deal - cheaper seats</Text>
                        </div>
                        <Card style={{
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column'
                        }}>
                            <SubTitle>Partner package</SubTitle>
                            <Spacer bottom={3}/>
                            <Text>{partner?.description || <Skeleton height={48}/>}</Text>
                            <Spacer bottom={3}/>
                            <Price style={{
                                flexDirection: 'column',
                                marginTop: 'auto'
                            }}>
                                <Title>
                                    {pricing === 'yearly' && partner?.unitPrice &&
                                    <Spacer inline right={3}>
                                        <del><small>${(partner.unitPrice + 2) * users}</small></del>
                                    </Spacer>}
                                    $
                                    {partner?.unitPrice
                                        ? partner.unitPrice * users
                                        : <Skeleton height={24} width={30}/>}
                                </Title>
                                <Spacer bottom={1}/>
                                <Text>
                                    /month
                                    <Spacer inline right={1}/>
                                    <Help text={`${users} users x $${partner?.unitPrice} per user`}>?</Help>
                                </Text>
                            </Price>
                            <Spacer bottom={3}/>
                            <Button
                                disabled={!partner?.id}
                                type='primary'
                                as={'a'}
                                href={'https://register.dspworkplace.com/register'}
                                target={'_blank'}
                                style={{display: 'block'}}
                            >Get started</Button>
                        </Card>
                    </Col>
                </Row>
            </Spacer>
        </Container>
    );
}

export default App;
