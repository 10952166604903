import React, {useRef, useEffect, useState} from 'react';
import {Link, NavLink} from 'react-router-dom';
import styled from 'styled-components';
import {Visible} from "react-grid-system";
import {Button, Icon, Spacer, Theme} from '@dspworkplace/ui';
import {Container, Divider} from '../../UI';
import Logo from '../../../assets/logo.svg';

const navSize = '95px';

const Nav = styled.nav`
    height: ${navSize};
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;      
    padding-top: 12px;
    
    @media (max-width: 768px) {
        position: fixed;
        z-index: 10;
        height: auto;
        width: auto;
        top: 0;
        left: 0;
        right: 0;
        padding-left: 15px;
        padding-right: 15px;
        padding-bottom: 8px;
        background: white;
        flex-wrap: wrap;
        box-shadow: 0 3px 6px ${Theme.colors.neutrals.medium}33;
        
        .stack {
            position: relative;
            z-index: 1;
            width: 100%;
            height: auto;
            padding: 0;
            visibility: hidden;
            opacity: 0;
            height: 0;
            transition: all .2s;
            
            li,
            a {
                width: calc(100vw - 30px);
                text-align: center;
                display: block;
            }
            
            li > a {
                line-height: 60px;
            }
        }
        
        &.open {
            .stack {
                opacity: 1;
                visibility: visible;
                height: auto;
                
                + .stack {
                    padding-bottom: 12px;
                }
            }
        }
    }
`;

const Image = styled.img`
    height:52px;
    width:auto;
    vertical-align: super;
    max-width: 100%;
`;

const Menu = styled(Spacer)`
    flex: 1;
    align-items: center;
    height: ${navSize};
`;

const Tabs = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;
    height: 100%;
    // text-align: center;
    
    > li {
        display: inline-block;
        line-height: ${navSize};
            
        a {            
            font-size: ${Theme.font.medium.size}
            color: ${Theme.colors.neutrals.medium};
            font-family: ${Theme.font.main};
            font-weight: bold;
            padding: 0 32px;
            
            &.active {
                color: ${Theme.colors.secondary};
            }
        }
    }
`;


const App = ({routes}) => {
    const [open, setOpen] = useState(false);
    const ref = useRef();

    const handleOutsideClick = evt => {
        if (ref.current && !ref.current.contains(evt.target))
            setOpen(false);
    };

    useEffect(() => {
        document.addEventListener('click', handleOutsideClick);

        return ()=> document.removeEventListener('click', handleOutsideClick);
    }, []);

    return(
        <Container>
            <Visible xs sm md>
                <Spacer top={25}/>
            </Visible>

            <Nav className={open ? 'open' : ''} ref={ref}>
                <NavLink to={'/'}>
                    <Image src={Logo} />
                </NavLink>
                <Visible lg xl xxl>
                    <Spacer right={8}/>
                </Visible>

                <Visible xs sm md>
                    <Button style={{
                        paddingRight: 8,
                        paddingLeft: 8
                    }} onClick={()=>setOpen(!open)}>
                        <Icon.Menu size='28px' style={{verticalAlign: 'middle'}}/>
                    </Button>
                    {open && <Divider/>}
                </Visible>

                <Menu className='stack'>
                    <Tabs>
                        {routes.filter(r => r.useMenu !== false).map((route,i) =>
                            <li key={i}>
                                <NavLink to={route.path} exact>{route.name}</NavLink>
                            </li>
                        )}
                    </Tabs>
                </Menu>

                <Spacer left={8} className='stack'>
                    <Button as={Link} to={'/register'} type='primary'>Start Free 30-Day Trial</Button>
                    <Spacer inline right={3}/>
                    <Button as='a' href='https://app.dspworkplace.com/'>Login</Button>
                </Spacer>
            </Nav>
        </Container>
    );
}

export {
    Menu,
    Tabs,
    Image
};

export default App;
