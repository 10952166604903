import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {Row, Col, Visible, useScreenClass} from "react-grid-system";
import styled from "styled-components";
import {Button, Icon, Spacer, SubTitle, Theme, ThirdTitle, Title, Text} from "@dspworkplace/ui";
import * as assets from '../../assets';
import {BigTitle, Container, Divider, LightBlueSection} from "../../Components/UI";
import FAQ from "../../Components/Sections/FAQ";
import Footer from "../../Components/Sections/Footer";

const Point = styled.div`
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    font-family: ${Theme.font.main};
    font-size: ${Theme.font.big.size};
    font-weight: bold;
    color: ${Theme.colors.neutrals.white};
    background: ${Theme.colors.info.border};
    border: 2px solid ${Theme.colors.neutrals.white};
    box-shadow: 0 3px 6px ${Theme.colors.info.shadow};
    border-radius: 100%;
    display: inline-block;
    margin-right: 12px;
    
    ${props => props.position && `
    position: absolute;
    top: ${props.position.top}%;
    left: ${props.position.left}%;
    `} 
    
    transition: all .2s;
    
    &.highlight {
        background: ${Theme.colors.primary};
        box-shadow: 0 3px 6px ${Theme.colors.warnings.shadow};
    }
`;

const PointsSection = ({animate = true, ...props}) => {
    const [points, setPoints] = useState(props.points);

    const highlight = k => {
        let newPoints = points.map(p => ({
            ...p,
            highlight: false
        }));

        if (newPoints[k]) {
            newPoints[k].highlight = true;
            setPoints(newPoints);
        }
    }

    useEffect(() => {
        if (!animate)
            return ()=>{};

        const timeout = 3000;
        const loop = setInterval(() => {
            let highlighted = points.findIndex(p => p.highlight === true);
            let next = highlighted + 1 >= points.length ? 0 : highlighted + 1;
            highlight(next);
        }, timeout);

        return () => setTimeout(()=> clearInterval(loop), timeout + 1);
    }, [animate, points]);

    return (
        <Container>
            <Row>
                <Col lg={7}>
                    <BigTitle>{props.title}</BigTitle>
                    <Spacer bottom={10}/>

                    {points.map((point, k) =>
                        <div key={k}>
                            <SubTitle style={{color: point.highlight ? Theme.colors.primary : false}}>
                                <Point className={`${point.highlight ? 'highlight' : ''}`}>{k+1}</Point>
                                {point.title}
                            </SubTitle>
                            <Spacer top={1} bottom={10} left={13} right={10}>
                                <Text>{point.text}</Text>
                            </Spacer>
                        </div>
                    )}
                </Col>
                <Col lg={5} style={{position: 'relative'}}>
                    {points.filter(p => p.position).map((point, k) =>
                        <Point
                            key={k}
                            position={point.position}
                            className={`${point.highlight ? 'highlight' : ''}`}
                        >{k+1}</Point>
                    )}
                    <img src={props.image} alt="" width='100%'/>
                </Col>
            </Row>
        </Container>
    )
}

const App = () => {
    const screenClass = useScreenClass();

    return (
        <>
            <Spacer top={['lg', 'xl', 'xxl'].includes(screenClass) ? 20 : 5} bottom={15}>
                <PointsSection
                    title='Scheduling'
                    image={assets.scheduler}
                    points={[
                        {
                            title: 'Complete scheduler with DSP-specific reporting',
                            text: 'Use our industry standard scheduling tools with specific functions only a DSP needs, ' +
                                'including ready-to-go, built-in reports. ',
                            position: {
                                top: 34,
                                left: 65
                            }
                        },
                        {
                            title: 'Route coverage',
                            text: 'Use our balance group views to locate your coverage gaps. ',
                            position: {
                                top: 75,
                                left: 53
                            }
                        },
                        {
                            title: 'Manage overtime',
                            text: 'See and fix your overtime issues before they happen.  Our intuitive interface will proactively ' +
                                'notify you if you are at risk of scheduling a driver for overtime. ',
                            position: {
                                top: 50,
                                left: 12
                            }
                        },
                    ]}
                />
            </Spacer>

            <Spacer top={15} bottom={15} style={{
                background: `${Theme.colors.action}11`
            }}>
                <PointsSection
                    animate={false}
                    title='Workflow tools'
                    image={assets.workflow}
                    points={[
                        {
                            title: 'On-time dispatch of your team',
                            text: 'Use our load out and RTS module to help train and manage drivers through these ' +
                                'ever changing processes.'
                        },
                        {
                            title: <><span style={{color: Theme.colors.neutrals.gray}}>dsp</span>
                                <span style={{color: Theme.colors.secondary}}>workplace</span> workbot</>,
                            text: 'Let our workbot shepherd tasks through your organization to make sure all ' +
                                'steps of the process are followed every time.'
                        },
                        {
                            title: 'Modify existing and create new workflows',
                            text: 'Our incidents module will allow you to edit our existing workflow processes to customize ' +
                                'them to the needs of your station and/or business and, if we missed one, you can even create your own.'
                        }
                    ]}
                />
            </Spacer>

            <Spacer top={15}>
                <PointsSection
                    animate={false}
                    title='Mobile app'
                    image={assets.app}
                    points={[
                        {
                            title: 'Integrated chat',
                            text: 'Reduce the number of applications your drivers need to use on a daily basis.'
                        },
                        {
                            title: 'Online schedule',
                            text: 'Allow your drivers to see their assigned schedule real-time and communicate with ' +
                                'their supervisor.'
                        },
                        {
                            title: 'Let drivers self-serve',
                            text: 'Drivers can view and pick up open shifts as well as arrange to swap with another ' +
                                'driver - a workflow that will require a built in supervisor’s approval, of course. '
                        }
                    ]}
                />
            </Spacer>

            <Spacer all={15}>
                <Divider/>
            </Spacer>

            <Spacer bottom={15}>
                <Container>
                    <Title style={{textAlign: 'center'}}>More features</Title>
                    <Row>
                        <Col xs={12} lg={4}>
                            <Spacer all={['lg', 'xl', 'xxl'].includes(screenClass) ? 10 : 5}>
                                <SubTitle>Time tracking</SubTitle>
                                <Spacer bottom={3}/>
                                <ThirdTitle>2 geofences</ThirdTitle>
                                <Spacer bottom={1}/>
                                <Text>
                                    Track the times of ins and outs of both the parking lot and the station geofences.
                                </Text>

                                <Spacer top={5}/>
                                <ThirdTitle>Incident time and location</ThirdTitle>
                                <Spacer bottom={1}/>
                                <Text>
                                    Know when and where one of our incident forms was opened by a driver.
                                </Text>

                                <Spacer top={5}/>
                                <ThirdTitle>Inventory timelines</ThirdTitle>
                                <Spacer bottom={1}/>
                                <Text>
                                    Keep track of when each van and device were used in our timelines.
                                </Text>
                            </Spacer>
                        </Col>
                        <Col xs={12} lg={4}>
                            <Spacer all={['lg', 'xl', 'xxl'].includes(screenClass) ? 10 : 5}>
                                <SubTitle>Live chat</SubTitle>
                                <Spacer bottom={3}/>
                                <ThirdTitle>Leverage integration for better communication</ThirdTitle>
                                <Spacer bottom={1}/>
                                <Text>
                                    Use integrated chat to communicate with your drivers. Create ‘wildcard’ groups that auto-update. Send via SMS as well.
                                </Text>

                                <Spacer top={5}/>
                                <ThirdTitle>Share more than just text</ThirdTitle>
                                <Spacer bottom={1}/>
                                <Text>
                                    Send or share documents, pictures, videos and URLs.
                                </Text>

                                <Spacer top={5}/>
                                <ThirdTitle>Dynamic station membership</ThirdTitle>
                                <Spacer bottom={1}/>
                                <Text>
                                    No need to maintain this list anymore. If they are an active employee assigned to
                                    your station, they are automatically members of the station chat list.
                                </Text>
                            </Spacer>
                        </Col>
                        <Col xs={12} lg={4}>
                            <Spacer all={['lg', 'xl', 'xxl'].includes(screenClass) ? 10 : 5}>
                                <SubTitle>Announcements</SubTitle>
                                <Spacer bottom={3}/>
                                <ThirdTitle>Post morning messages</ThirdTitle>
                                <Spacer bottom={1}/>
                                <Text>
                                    On our mobile app, share important messages with your team. Incorporate text, photos and video content. Stack multiple slides, change the order, and add a time restriction to make them stay on the screen.
                                </Text>

                                <Spacer top={5}/>
                                <ThirdTitle>Use our templates to reward performance</ThirdTitle>
                                <Spacer bottom={1}/>
                                <Text>
                                   Build and save messages with a variety of purposes: safety, education, team awards, road conditions, and more.
                                </Text>

                                <Spacer top={5}/>
                                <ThirdTitle>Schedule recurring messages</ThirdTitle>
                                <Spacer bottom={1}/>
                                <Text>
                                    Create messages and schedule them to run whenever you want, again and again.
                                </Text>
                            </Spacer>
                        </Col>
                    </Row>
                </Container>
            </Spacer>

            <Spacer bottom={15}>
                <Divider/>
            </Spacer>

            <Spacer bottom={15}>
                <Container>
                    <Title style={{textAlign: 'center'}}>Coming soon</Title>
                    <Row>
                    <Col xs={12} lg={4}>
                            <Spacer all={['lg', 'xl', 'xxl'].includes(screenClass) ? 10 : 5}>
                                <SubTitle>Reporting module</SubTitle>
                                <Spacer bottom={3}/>
                                <ThirdTitle>Build your own reports</ThirdTitle>
                                <Spacer bottom={1}/>
                                <Text>
                                    Leverage all of the amazing data gathered by the app to help you run your business with customized reports.
                                </Text>

                                <Spacer top={5}/>
                                <ThirdTitle>Upgraded dashboard</ThirdTitle>
                                <Spacer bottom={1}/>
                                <Text>
                                    Turn the reports you create into widgets, then put them on your dashboard so you can easily see your opportunities before they become issues.
                                </Text>

                                <Spacer top={5}/>
                                <ThirdTitle>Attrition report</ThirdTitle>
                                <Spacer bottom={1}/>
                                <Text>
                                    Need a more accurate and insightful report?  We have one and it will let you head off these issues where possible.
                                </Text>
                            </Spacer>
                        </Col>
                        <Col xs={12} lg={4}>
                            <Spacer all={['lg', 'xl', 'xxl'].includes(screenClass) ? 10 : 5}>
                                <SubTitle>HRIS</SubTitle>
                                <Spacer bottom={3}/>
                                <ThirdTitle>Onboarding tools</ThirdTitle>
                                <Spacer bottom={1}/>
                                <Text>
                                    Beautiful, simple, mobile enabled. Everything you need.
                                </Text>

                                <Spacer top={5}/>
                                <ThirdTitle>More workflows</ThirdTitle>
                                <Spacer bottom={1}/>
                                <Text>
                                    Manage and report on not just the onboarding process, but concessions, reviews, disciplinary action, and especially your employee successes.
                                </Text>

                                <Spacer top={5}/>
                                <ThirdTitle>Payroll provider integration</ThirdTitle>
                                <Spacer bottom={1}/>
                                <Text>
                                    Once hired, have all of the data you just gathered automatically flow into your payroll provider.
                                </Text>
                            </Spacer>
                        </Col>
                        <Col xs={12} lg={4}>
                            <Spacer all={['lg', 'xl', 'xxl'].includes(screenClass) ? 10 : 5}>
                                <SubTitle>Fleet management module</SubTitle>
                                <Spacer bottom={3}/>
                                <ThirdTitle>Mileage-based maintenance tracking</ThirdTitle>
                                <Spacer bottom={1}/>
                                <Text>
                                    Have our workbot watch for oil changes and more based on the mileage being logged daily and using settings of your choice.
                                </Text>

                                <Spacer top={5}/>
                                <ThirdTitle>Vehicle document storage</ThirdTitle>
                                <Spacer bottom={1}/>
                                <Text>
                                    Store any vehicle related document in our attached repository, including the DOT equipment file.
                                </Text>

                                <Spacer top={5}/>
                                <ThirdTitle>Automated vendor communication</ThirdTitle>
                                <Spacer bottom={1}/>
                                <Text>
                                    Track your preferred vendors and notify them when you have required work and even track communications as a part of the vehicle record.
                                </Text>
                            </Spacer>
                        </Col>
                    </Row>
                </Container>
            </Spacer>

            <FAQ/>

     

            <Footer/>
        </>
    );
}

export default App;
