export const isDev = process.env.NODE_ENV === 'development';

export default function ()
{
    const controller = new AbortController();
    const signal = controller.signal;

    const abort = () => controller.abort();

    const fetchPackages = () =>
        fetch(
            `${process.env.REACT_APP_API_ENDPOINT}/api/subscription/get-available-products?type=package&testMode=${isDev}`,
            {signal},
        ).then(
            response => response.json()
        );

    const fetchAddons = () =>
        fetch(
            `${process.env.REACT_APP_API_ENDPOINT}/api/subscription/get-available-products?type=addon&testMode=${isDev}`,
            {signal},
        ).then(
            response => response.json()
        );

    return {
        abort,
        fetchPackages,
        fetchAddons,
    }
}