import React, {useCallback, useEffect, useState} from 'react';
import styled from "styled-components";
import {Col, Hidden, Row, useScreenClass} from 'react-grid-system';
import {Link} from "react-router-dom";
import {Button, ButtonGroup, Icon, Spacer, SubTitle, Text, Theme, ThirdTitle, Title} from '@dspworkplace/ui';
import {BigTitle, Card, Container, Divider, Skeleton} from "../../Components/UI";
import {garageDispatcher} from "../../assets";
import FAQ from "../../Components/Sections/FAQ";
import Footer from "../../Components/Sections/Footer";
import Range from "../../Components/Range";
import {Item, Yes} from "../../Components/Sections/Comparison";
import Testimonials from "../../Components/Sections/Testimonials";
import api, {isDev} from "../../api";

export const FEATURES = [
    {
        name: 'DSP scheduling application - includes shift management',
        basic: true,
        partner: true,
    },
    {
        name: 'Vehicle and device inventory tracking',
        basic: true,
        partner: true,
    },
    {
        name: 'Mobile, morning load out and RTS management workflows',
        basic: true,
        partner: true,
    },
    {
        name: 'Morning kick-off workflow for managing driver load out',
        basic: true,
        partner: true,
    },
    {
        name: 'RouteAssist&trade;',
        basic: true,
        partner: true,
    },
    {
        name: 'Fully integrated chat engine',
        basic: true,
        partner: true,
    },
    {
        name: 'Comprehensive mobile application (iOS and Android)',
        basic: true,
        partner: true,
    },
    {
        name: 'Mobile app pickup open and swap shifts',
        basic: true,
        partner: true,
    },
    {
        name: 'Payroll punch time integration',
        basic: isDev ? 'prod_KkPYQ7aFJ4pavZ' : 'prod_KwdRRqmjJ6MpBh',
        partner: true,
    },
    {
        name: 'Complete incident management workflows include: driver accident/injury forms, tier tracking, UPD tracker and driver call outs',
        basic: false,
        partner: true,
    },
    {
        name: 'Route commitment tracking',
        basic: false,
        partner: true,
    },
    {
        name: 'Schedule balance views',
        basic: false,
        partner: true,
    },
    {
        name: 'Package count information',
        basic: false,
        partner: true,
    },
    {
        name: 'Mobile app announcements: for driver updates and education',
        basic: false,
        partner: true,
    },
    {
        name: 'Build your own reports (coming soon)',
        basic: false,
        partner: true,
    },
    {
        name: 'Amazon invoice comparison tool (coming soon)',
        basic: false,
        partner: true,
    },
];


const Price = styled.span`
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    
    small {
        font-size: .5em;
        vertical-align: top;
    }
`;

export const Help = styled(Button)`
    line-height: 20px;
    height: 20px;
    width: 20px;
    padding: 0;
    font-size: 14px;
    border-radius: 100px;
    border: 1px solid ${Theme.colors.info.border};
    position: relative;
    
    &::after {
        display: block;
        content: "${props => props.text}";
        width: 94px;
        padding: 12px;
        background: ${Theme.colors.neutrals.white};
        border-radius: ${Theme.defaultRadius};
        box-shadow: 0 3px 6px ${Theme.colors.neutrals.gray}26;
        text-align: left;
        font-family: ${Theme.font.body};
        color: ${Theme.colors.neutrals.gray};
        font-weight: normal;
        position: absolute;
        top: 50%;
        left: 100%;
        transform: translate(4px, -50%);
    }
    
    &::before {
        content: "";
        display: block;
        right: -4px;
        top: 50%;
        border: solid transparent;
        height: 0;
        width: 0;
        position: absolute;
        border-color: rgba(255, 255, 255, 0);
        border-right-color: #fff;
        border-width: 8px;
        margin-top: -8px;
        z-index: 1;
    }
    
    &::before,
    &::after {
        opacity: 0;
        pointer-events: none;
        transition: all .3s;
    }
    
    &:hover,
    &:focus {
        &::before,
        &::after {
            opacity: 1;
        }
    }
`;

const App = () => {
    const [users, setUsers] = useState(60);
    const [packages, setPackages] = useState({});
    const [addons, setAddons] = useState([]);
    const [pricing, setPricing] = useState('yearly');
    const screenClass = useScreenClass();

    const {fetchPackages, fetchAddons, abort} = api();

    const fetchData = () =>
        Promise.all([
            fetchPackages(),
            fetchAddons(),
        ]).then(([packages, addons]) => {
            setPackages(packages);
            setAddons(addons);
        });

    useEffect(() => {
        fetchData();

        return () => abort();
    }, []);

    const {basic, partner} = packages;
    if (basic && partner)
        [basic, partner].forEach(p =>
            p.unitPrice = parseFloat((p.processorData.prices
                .find(price => price.recurring.interval === (pricing === 'yearly' ? 'year' : 'month'))
                .unit_amount_decimal / 100 * (pricing === 'yearly' ? (1/12) : 30)).toFixed(2))
        );

    const getMinPrice = useCallback(() => {
        let price = Infinity;

        for (const key in packages)
            packages[key].processorData.prices.forEach(p => {
                if (parseFloat(p.unit_amount_decimal) < price)
                    price = parseFloat(p.unit_amount_decimal)
            })

        return price !== Infinity ? (price * 30 / 100).toFixed(2) : false;
    }, [packages]);

    const itemColSize = ['xs'].includes(screenClass) ? 6 : 4;

    return (
        <>
            <Container>
                <Spacer top={15}/>
                <Row>
                    <Col sm={9} style={{display:'flex',flexDirection:'column',justifyContent:'center'}}>
                        <BigTitle>Simple and Affordable Pricing</BigTitle>
                        <Spacer top={4} />
                        <Spacer right={30}>
                            <Text>From ${getMinPrice() || <Skeleton height={20} width={20}/>}/user/month, start gathering better information and finding new efficiencies in your business. Take advantage of our free 30-day trial.</Text>
                        </Spacer>
                    </Col>
                    <Col sm={3}>
                        <Hidden xs>
                            <img src={garageDispatcher} style={{width: '100%'}} alt=""/>
                        </Hidden>
                    </Col>
                </Row>
            </Container>

            <Spacer top={20}>
                {/*<Divider/>*/}
            </Spacer>

            <Container style={{textAlign: 'center'}}>
                <Spacer right={0} left={0}>
                    <Row>
                        <Col md={4}>
                            <Row>
                                <Col md={10}>
                                    <Card>
                                        <ThirdTitle>How many users does<br/> your company have?</ThirdTitle>
                                        <Spacer bottom={2}/>
                                        <Text>Including drivers, managers, dispatchers, operations, etc</Text>
                                        <Spacer bottom={4}/>
                                        <Range
                                            defaultValue={users}
                                            min={10}
                                            max={1000}
                                            onChange={u => setUsers(parseInt(u))}
                                            size={'extraSmall'}
                                        />
                                        <Spacer bottom={4}/>
                                        <div style={{
                                            display: 'flex',
                                            alignItems: 'baseline',
                                            justifyContent: 'center'
                                        }}>
                                            <ThirdTitle>Preferred payment term:</ThirdTitle>
                                        </div>
                                        <Spacer bottom={2}/>
                                        <div style={{
                                            display: 'flex',
                                            alignItems: 'baseline',
                                            justifyContent: 'center'
                                        }}>
                                            <ButtonGroup
                                                active={pricing}
                                                size={'small'}
                                            >
                                                <Button track={'monthly'} onClick={()=>setPricing('monthly')}>Monthly</Button>
                                                <Button track={'yearly'} onClick={()=>setPricing('yearly')}>Annually</Button>
                                            </ButtonGroup>
                                        </div>
                                        <Spacer bottom={4}/>
                                        <Text><small>You can change your number of users at any time and pricing will automatically be updated.</small></Text>
                                        {/*<Text><small>If you need more than 1000 users contact us for special price</small></Text>*/}
                                    </Card>
                                </Col>
                                <Col md={2}>
                                    <Spacer bottom={25}/>
                                    <Icon.ArrowRight color={Theme.colors.info.border}/>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={4}>
                            <Card style={{
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column'
                            }}>
                                <SubTitle>Basic package</SubTitle>
                                <Spacer bottom={3}/>
                                <Text>{basic?.description || <Skeleton height={48}/>}</Text>
                                <Spacer bottom={3}/>
                                <Price style={{
                                    flexDirection: 'column',
                                    marginTop: 'auto'
                                }}>
                                    <Title>
                                        {pricing === 'yearly' && basic?.unitPrice &&
                                            <Spacer inline right={3}>
                                                <del><small>${(basic.unitPrice + .5) * users}</small></del>
                                            </Spacer>}
                                        $
                                        {basic?.unitPrice
                                            ? basic.unitPrice * users
                                            : <Skeleton height={24} width={30}/>}
                                    </Title>
                                    <Spacer bottom={1}/>
                                    <Text>
                                        /month
                                        <Spacer inline right={1}/>
                                        <Help text={`${users} users x $${basic?.unitPrice} per user`}>?</Help>
                                    </Text>
                                </Price>
                                <Spacer bottom={3}/>
                                <Button
                                    disabled={!basic?.id}
                                    type='primary'
                                    as={Link}
                                    to={{
                                        pathname: '/register',
                                        state: {
                                            packages: packages,
                                            addons: addons,
                                            pricing: pricing,
                                            tier: 'basic',
                                            users: users,
                                        }
                                    }}
                                    style={{display: 'block'}}
                                >Get Started</Button>
                            </Card>
                        </Col>
                        <Col md={4}>
                            <div style={{
                                background: Theme.colors.info.bg,
                                padding: 12,
                                borderRadius: `${Theme.defaultRadius} ${Theme.defaultRadius} 0 0`,
                                marginTop: -40,
                            }}>
                                <Text style={{
                                    fontSize: 12,
                                    color: Theme.colors.info.text
                                }}>Best deal - cheaper seats</Text>
                            </div>
                            <Card style={{
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column'
                            }}>
                                <SubTitle>Partner package</SubTitle>
                                <Spacer bottom={3}/>
                                <Text>{partner?.description || <Skeleton height={48}/>}</Text>
                                <Spacer bottom={3}/>
                                <Price style={{
                                    flexDirection: 'column',
                                    marginTop: 'auto'
                                }}>
                                    <Title>
                                        {pricing === 'yearly' && partner?.unitPrice &&
                                        <Spacer inline right={3}>
                                            <del><small>${(partner.unitPrice + 2) * users}</small></del>
                                        </Spacer>}
                                        $
                                        {partner?.unitPrice
                                            ? partner.unitPrice * users
                                            : <Skeleton height={24} width={30}/>}
                                    </Title>
                                    <Spacer bottom={1}/>
                                    <Text>
                                        /month
                                        <Spacer inline right={1}/>
                                        <Help text={`${users} users x $${partner?.unitPrice} per user`}>?</Help>
                                    </Text>
                                </Price>
                                <Spacer bottom={3}/>
                                <Button
                                    disabled={!partner?.id}
                                    type='primary'
                                    as={Link}
                                    to={{
                                        pathname: '/register',
                                        state: {
                                            packages: packages,
                                            addons: addons,
                                            pricing: pricing,
                                            tier: 'partner',
                                            users: users,
                                        }
                                    }}
                                    style={{display: 'block'}}
                                >Get started</Button>
                            </Card>
                        </Col>
                    </Row>
                </Spacer>
            </Container>

            <Container>
                <Spacer top={10}/>
                <Row justify={'flex-end'}>
                    <Col md={8}>
                        <Text style={{textAlign: 'center', color: Theme.colors.info.text}}>Compare plans</Text>
                    </Col>
                </Row>

                <Spacer top={5}/>
                {FEATURES.map(({name, basic, partner, ...p}) =>
                    <div key={name}>
                        <Divider full/>
                        <Row align={'center'}>
                            <Col md={itemColSize}>
                                <Item right>{name}</Item>
                            </Col>
                            <Col>
                                {basic === true
                                    ? <Yes/>
                                    : typeof basic === 'string'
                                        ? <ThirdTitle style={{textAlign: 'center', fontWeight: 'normal'}}>
                                            <span style={{
                                                fontWeight: 'bold',
                                                background: Theme.colors.secondary,
                                                color: Theme.colors.neutrals.white,
                                                borderRadius: Theme.defaultRadius,
                                                padding: '0 4px',
                                                fontSize: Theme.font.extraSmall.size,
                                            }}>add-on</span>
                                                <Spacer left={2} inline>
                                                    $
                                                    {(() => {
                                                        const price = addons.find(a => a.processorId === basic)
                                                            ?.processorData.prices[0].unit_amount_decimal;

                                                        return price
                                                            ? price / 100
                                                            : <Skeleton height={20} width={20}/>;
                                                    })()}
                                                    /user/month
                                                </Spacer>
                                            </ThirdTitle>
                                        : null}
                            </Col>
                            <Col>
                                <Yes/>
                            </Col>
                        </Row>
                    </div>
                )}

                {/*
                <Spacer top={10}/>
                <Row justify={'flex-end'}>
                    <Col md={8}>
                        <Text style={{textAlign: 'center', color: Theme.colors.info.text}}>Available Add-ons</Text>
                    </Col>
                </Row>
                <Spacer top={5}/>

                {addons.map(({name}) =>
                    <div key={name}>
                        <Divider full/>
                        <Row align={'center'}>
                            <Col md={itemColSize}>
                                <Item right>{name}</Item>
                            </Col>
                            <Col>
                                <Yes/>
                            </Col>
                            <Col>
                                <Yes/>
                            </Col>
                        </Row>
                    </div>
                )}
                */}
            </Container>

            <Spacer top={10} bottom={10}>
                <Divider/>
            </Spacer>

            <Container>
                <Title style={{textAlign: 'center'}}>Features</Title>
                {/*<Spacer bottom={10}/>*/}

                <Row>
                    <Col sm={4}>
                        <Spacer all={['lg', 'xl', 'xxl'].includes(screenClass) ? 10 : 5}>
                            {/*<Icon.Calendar color={Theme.colors.info.text} size='28px'/>*/}
                            {/*<Spacer bottom={1}/>*/}
                            <SubTitle>Scheduling</SubTitle>

                            <Spacer bottom={3}/>
                            <ThirdTitle>Complete scheduler with DSP-specific reporting</ThirdTitle>
                            <Spacer bottom={1}/>
                            <Text>
                                Industry standard scheduling tools with specific functions only a DSP needs, not to
                                mention built in reports, ready to go.
                            </Text>

                            <Spacer bottom={3}/>
                            <ThirdTitle>Route coverage</ThirdTitle>
                            <Spacer bottom={1}/>
                            <Text>
                                Use our balance group views to locate your coverage gaps.
                            </Text>

                            <Spacer bottom={3}/>
                            <ThirdTitle>Manage your overtime</ThirdTitle>
                            <Spacer bottom={1}/>
                            <Text>
                                See and fix your overtime issues before they happen. Our intuitive UI will show you
                                the risks and remind you if you are putting a driver at risk of overtime before you
                                schedule her.
                            </Text>
                        </Spacer>
                    </Col>
                    <Col sm={4}>
                        <Spacer all={['lg', 'xl', 'xxl'].includes(screenClass) ? 10 : 5}>
                            {/*<Icon.DspwNetwork color={Theme.colors.info.text} size='28px'/>*/}
                            {/*<Spacer bottom={1}/>*/}
                            <SubTitle>Workflow tools</SubTitle>

                            <Spacer bottom={3}/>
                            <ThirdTitle>On-time dispatch of your team</ThirdTitle>
                            <Spacer bottom={1}/>
                            <Text>
                                Use our load out and RTS module to help train and manage drivers through these ever-
                                changing processes.
                            </Text>

                            <Spacer bottom={3}/>
                            <ThirdTitle>Workflow tools</ThirdTitle>
                            <Spacer bottom={1}/>
                            <Text>
                                Let our workbot shepherd tasks throughout your organization to make sure the little
                                things are done every time.
                            </Text>

                            <Spacer bottom={3}/>
                            <ThirdTitle>More complicated workflows</ThirdTitle>
                            <Spacer bottom={1}/>
                            <Text>
                                Our incidents page will allow you to manage DSP specific processes and assign their
                                tasks to anyone in your organization.
                            </Text>
                        </Spacer>
                    </Col>
                    <Col sm={4}>
                        <Spacer all={['lg', 'xl', 'xxl'].includes(screenClass) ? 10 : 5}>
                            {/*<Icon.Android color={Theme.colors.info.text} size='28px'/>*/}
                            {/*<Spacer bottom={1}/>*/}
                            <SubTitle>Mobile app</SubTitle>

                            <Spacer bottom={3}/>
                            <ThirdTitle>Integraded chat</ThirdTitle>
                            <Spacer bottom={1}/>
                            <Text>
                                Reduce the number of applications your drivers need to use on a daily basis.
                            </Text>

                            <Spacer bottom={3}/>
                            <ThirdTitle>Online schedule</ThirdTitle>
                            <Spacer bottom={1}/>
                            <Text>
                                Allow your drivers to see their assigned schedule real-time and communicate with
                                their supervisor.
                            </Text>

                            <Spacer bottom={3}/>
                            <ThirdTitle>Let drivers self-serve</ThirdTitle>
                            <Spacer bottom={1}/>
                            <Text>
                                Drivers can view and pick up open shifts as well as arrange to swap a shift with another driver - a workflow that will require a built in supervisor’s approval, of course.
                            </Text>
                        </Spacer>
                    </Col>
                    <Col sm={4}>
                        <Spacer all={['lg', 'xl', 'xxl'].includes(screenClass) ? 10 : 5}>
                            {/*<Icon.Clock color={Theme.colors.info.text} size='28px'/>*/}
                            {/*<Spacer bottom={1}/>*/}
                            <SubTitle>Time tracking</SubTitle>

                            <Spacer bottom={3}/>
                            <ThirdTitle>2 geofences</ThirdTitle>
                            <Spacer bottom={1}/>
                            <Text>
                                Track the times of ins and outs of both the parking lot and the station geofences.
                            </Text>

                            <Spacer top={3}/>
                            <ThirdTitle>Incident time and location</ThirdTitle>
                            <Spacer bottom={1}/>
                            <Text>
                                Know when and where incident forms were created.
                            </Text>

                            <Spacer top={3}/>
                            <ThirdTitle>Inventory timelines</ThirdTitle>
                            <Spacer bottom={1}/>
                            <Text>
                                Keep track of when vehicles and devices were used.
                            </Text>
                        </Spacer>
                    </Col>
                    <Col sm={4}>
                        <Spacer all={['lg', 'xl', 'xxl'].includes(screenClass) ? 10 : 5}>
                            {/*<Icon.Chat color={Theme.colors.info.text} size='28px'/>*/}
                            {/*<Spacer bottom={1}/>*/}
                            <SubTitle>Live chat</SubTitle>

                            <Spacer bottom={3}/>
                            <ThirdTitle>Leverage integration for better communication</ThirdTitle>
                            <Spacer bottom={1}/>
                            <Text>
                                Scheduler integrated chat used to communicate with your drivers with SMS capabilities.
                            </Text>

                            <Spacer top={3}/>
                            <ThirdTitle>Share more than just text</ThirdTitle>
                            <Spacer bottom={1}/>
                            <Text>
                                Send or share documents, pictures, videos and URLs.
                            </Text>

                            <Spacer top={3}/>
                            <ThirdTitle>Dynamic station membership</ThirdTitle>
                            <Spacer bottom={1}/>
                            <Text>
                                No need to maintain this list anymore. If they are an active employee assigned to
                                your station, they are automatically members of the station chat list.
                            </Text>
                        </Spacer>
                    </Col>
                    <Col sm={4}>
                        <Spacer all={['lg', 'xl', 'xxl'].includes(screenClass) ? 10 : 5}>
                            {/*<Icon.TimeLine color={Theme.colors.info.text} size='28px'/>*/}
                            {/*<Spacer bottom={1}/>*/}
                            <SubTitle>Inventory tracking</SubTitle>

                            <Spacer bottom={3}/>
                            <ThirdTitle>Mobile phone</ThirdTitle>
                            <Spacer bottom={1}/>
                            <Text>
                                Track your mobile devices and their condition.
                            </Text>

                            <Spacer top={3}/>
                            <ThirdTitle>Fleet</ThirdTitle>
                            <Spacer bottom={1}/>
                            <Text>
                                Track owned, leased, and rented vehicles. View timelines, condition, and lease info
                                all in one place.
                            </Text>

                            <Spacer top={3}/>
                            <ThirdTitle>Who used it last?</ThirdTitle>
                            <Spacer bottom={1}/>
                            <Text>
                                Track who last used your devices and vehicles and when in our timelines.
                            </Text>
                        </Spacer>
                    </Col>
                </Row>
            </Container>

            {addons && (
                <Container style={{textAlign: 'center'}}>
                    <Spacer top={5} bottom={10}>
                        <Divider/>
                    </Spacer>
                    <Spacer bottom={10}>
                        <BigTitle>Available add-ons</BigTitle>
                        <Spacer top={3}/>
                        <Text>Achieve even more with the available add-ons</Text>
                    </Spacer>
                    <Row justify={'center'}>
                        {addons.map((a, i) =>
                            <Col key={i} lg={4}>
                                <Spacer all={5}>
                                    <SubTitle>{a.name}</SubTitle>
                                    <Spacer bottom={2}/>
                                    <ThirdTitle>
                                        {/*{a.amount > 0 ? (*/}
                                        {/*    <Price>*/}
                                        {/*        <span>$</span>*/}
                                        {/*        {a.amount}*/}
                                        {/*        <span>/{a.unit.replace('per_', '')}/month</span>*/}
                                        {/*    </Price>*/}
                                        {/*) : (*/}
                                        {/*    'Free'*/}
                                        {/*)}*/}
                                    </ThirdTitle>
                                    <Spacer bottom={2}/>
                                    <Text>{a.description}</Text>
                                </Spacer>
                            </Col>
                        )}
                    </Row>
                </Container>
            )}

            <Spacer bottom={15}/>

            <FAQ/>

            <Spacer top={15}/>
            <Testimonials/>

            <Footer/>
        </>
    );
}

export default App;
