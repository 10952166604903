import React, {useEffect} from 'react';
import {Route} from 'react-router-dom';

const BlankLayout = ({component: Component, ...rest}) => {
    useEffect(() => window.scrollTo(0,0), []);

    return (
        <Route {...rest} exact={rest.path === '/'} render={matchProps => (
            <Component {...matchProps} />
        )}/>
    )
};

export default BlankLayout;
