import React, {useState} from 'react';
import styled from 'styled-components';
import {Theme, Input, Spacer} from "@dspworkplace/ui";
import Icon from '../../assets/range_icon.png';

const NumberInput = styled(Input).attrs({type:'number'})`
    text-align:center;
    -moz-appearance:textfield;
    
    &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }
`;

let progressStyle = '';

for(let i=0;i<=100;++i) {
    progressStyle += `
        &[data-progress="${i}"]::-webkit-slider-runnable-track {
            background: linear-gradient(#7C98B6, #7C98B6) 0/ ${i}% 100% no-repeat #fff
        }
        
        &[data-progress="${i}"]::-moz-slider-runnable-track {
            background: linear-gradient(#7C98B6, #7C98B6) 0/ ${i}% 100% no-repeat #fff
        }
        
        &[data-progress="${i}"]::-ms-track {
            background: linear-gradient(#7C98B6, #7C98B6) 0/ ${i}% 100% no-repeat #fff
        }
    
    `
}

const sliderThumb = `
     -webkit-appearance: none;
    height:20px;
    width:20px;
    border-radius:20px;    
    background:url(${Icon}) no-repeat center center ${Theme.colors.neutrals.silver};
    cursor:pointer;
    margin-top:-10px;
`;

const sliderTrack = `
    -webkit-appearance: none;
    border:1px solid ${Theme.colors.info.border};
    height:4px;
    width:100%;
    border-radius:100px;   
`;

const isIe = document.documentMode;

// const Range = styled(Input).attrs({type:'range'})`
const Range = styled('input').attrs({type:'range'})`
    width:100%;
    height:${isIe ? '55px' : '4px'};
    -webkit-appearance: none;
    -moz-apperance: none;    
    border:none;
    outline:none;
    background:transparent;


    &:focus, &:hover, &:active {
        box-shadow:none;
        border:none;
        outline:none;
    }
    
    &::-ms-track {
       ${sliderTrack}
       background:transparent;
       color:transparent;       
    }

    &::-webkit-slider-runnable-track {
       ${sliderTrack}
    }
    
    &::-moz-slider-runnable-track {
       ${sliderTrack}
    }
    
    &::slider-runnable-track {
       ${sliderTrack}
    }
    
   ${progressStyle}
    

    &::-webkit-slider-thumb {
       ${sliderThumb}
    }
    
    &::-moz-slider-thumb {
       ${sliderThumb}
    }
    
    &::active {
        &::ms-thumb {
            box-shadow:none;
            outline:none;
        }
    }
    &::-ms-thumb {
       ${sliderThumb}
       margin-top:0px;
    }
`;

const App = ({
    defaultValue = 1,
    min = 1,
    max = 999,
    onChange = ()=>{},
    ...props
}) => {
    const [value, setValue] = useState(defaultValue);
    const [progress, setProgress] = useState(Math.round(value * 100 / max));

    const handleChange = (e) => {
        let value = e.target.value;
        if(value > max){
            value = max;
        }

        if (!value)
            value = 0;

        // if(value < min){
        //     value = min
        // }

        setValue(value);
        const p = value * 100 / max;
        setProgress(Math.round(p))
        onChange(value);
    }

    return (
        <>
            <NumberInput
                value={value}
                onChange={handleChange}
                {...props}
            />
            <Spacer top={2} />

            <Range
                type='range'
                min={min}
                max={max}
                value={value}
                data-progress={progress}
                onChange={handleChange}
                onBlur={props.onBlur}
            />
        </>
    );
}

export default App;

