import React, {useState} from "react";
import styled, {keyframes} from "styled-components";
import {Alert, Button, Dropdown, Icon, Input, Spacer, SubTitle, Theme, ThirdTitle} from "@dspworkplace/ui";
import {Col, Row, useScreenClass, Visible} from "react-grid-system";
import Logo from '../../assets/logo.svg';
import DMGgo from './dmggo.svg';
import Footer from "../../Components/Sections/Footer";
import {Container, Loading} from "../../Components/UI";
import {useForm} from "react-hook-form";

const Body = styled.div`
    min-height: 100vh;
    background-image: linear-gradient(50deg, #f05331 0%, ${Theme.colors.primary} 100%);
    
    @media (max-width: 768px) {
        background-image: linear-gradient(50deg, #f05331 65%, ${Theme.colors.primary} 100%);
    }
`;

const Header = styled.header`
    font: bold 3em/1 ${Theme.font.main};
    letter-spacing: -1px;
    color: white;
    text-shadow: 0px 1px 2px ${Theme.colors.neutrals.black}55;
    text-align: center;
    padding: 4rem 33% 0;

    min-height: 27vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 0;
    
    background-repeat: no-repeat;
    background-image: url(${Logo}), url(${DMGgo});
    background-size: 33% auto, 33% auto;
    background-position: left -8% center, right -8% center;
    
    p {
        position: relative;
        z-index: 1;
    }
    
    &::after,
    &::before {
        content: "";
        display: block;
        height: 100%;
        width: 5vw;
        position: absolute;
        top: 0;
        border-radius: 50%;       
        opacity: .8;
        z-index: 0;
        
        background-color: #f05331;
        box-shadow: 0 0 100px 100px #f05331;
        left: 0;
        transform: translateX(-50%);
    }
    
    &::before {
        background-color: ${Theme.colors.primary};
        box-shadow: 0 0 100px 100px ${Theme.colors.primary};
        left: auto;
        right: 0;
        transform: none;
    }
    
    @media (max-width: 768px) {
        font-size: 2rem;
        padding: 4rem 10% 0;
        min-height: 22vh;
        
        background-size: 50% auto, 50% auto;
        background-position: left -8% top 15%, right -8% top 15%;
    }
`;

const BounceAnimation = keyframes`
    0% { top: 0; }
    50% { top: -.5rem }
    100% { top: 0 }
`;

const LearnMore = styled.div`
    position: absolute;
    bottom: -2em;
    font-size: 1rem;
    font-weight: normal;
    letter-spacing: 0;
    opacity: .7;
    cursor: pointer;
    
    svg {
        position: relative;
        display: block;
        margin: auto;
        animation: ${BounceAnimation} 1.5s linear infinite;
    }
`;

const Content = styled.main`
    position: relative;
    z-index: 1;
    padding-top: 3em;
    background: linear-gradient(-2deg, rgba(255,255,255,1) 60%, rgba(255,255,255,0) 95%);
`;

const Wrapper = styled.div`
    max-width: 90%;
    width: 760px;
    margin: auto;
    text-align: justify;
`;

const SubTitleWithIcon = ({children}) => {
    return (
        <SubTitle
            style={{
                color: Theme.colors.secondary,
                textAlign: 'left',
                display: 'flex',
            }}
        >
            <Icon.CheckNarrow
                size={'20px'}
                color={Theme.colors.primary}
                style={{
                    flexShrink: 0,
                    position: 'relative',
                    top: 2
                }}
            />
            <Spacer left={2}>
                {children}
            </Spacer>
        </SubTitle>
    )
}

const ContactForm = () => {
    const {register, watch, setValue, errors, handleSubmit} = useForm();
    const [status, setStatus] = useState();

    const onSubmit = data => {
        setStatus('loading');

        fetch('https://fbce9jiuxa.execute-api.us-west-2.amazonaws.com/staging/handlecontact', {
            method: 'POST',
            body: JSON.stringify(data)
        })
        .then(response => {
            if (response.status === 200)
                setStatus({success: "Thank you! We'll get back to you ASAP!"})
            else
                throw new Error();
        })
        .catch(() => {
            setStatus({error: "Sorry, your submission failed to send. Please contact us at sales@dspworkplace.com or sales@dmggo.com."})
        })
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <SubTitle>Contact Us</SubTitle>
            <Spacer bottom={3}/>
            <Input
                ref={register({
                    validate: {
                        email: v => {
                            let i = document.createElement('input');
                            i.type = 'email';
                            i.value = v;
                            return !i.validity.typeMismatch || 'Please enter a valid email';
                        }
                    },
                    required: {
                        value: true,
                        message: 'Please enter your email'
                    },
                })}
                name='email'
                label='Email'
                type='email'
                Required
                valid={watch('email') && !errors?.email?.message}
                error={errors?.email?.message}
            />
            <Spacer bottom={3}/>
            <Input
                ref={register}
                name='name'
                label='Name'
            />
            <Spacer bottom={3}/>
            <Input
                ref={register}
                name='phone'
                label='Phone'
                type='tel'
            />
            <Spacer bottom={3}/>
            <Input
                ref={register}
                name='company'
                label='Company Name'
            />
            <Spacer bottom={3}/>
            <Dropdown
                ref={register({
                    required: {
                        value: true,
                        message: 'Please select an option'
                    }
                })}
                name='subject'
                label='How can we help?'
                options={[
                    {name: 'Sign me up for a demo ASAP...', value: 'Sign me up for a demo ASAP...'},
                    {name: 'Have DMGgo call me, like yesterday!', value: 'Have DMGgo call me, like yesterday!'},
                    {name: 'I need it all, now!', value: 'I need it all, now!'},
                    {name: 'Other', value: 'Other'},
                ]}
                visibleOptionsQty={4}
                onChange={v => setValue('subject', v.value)}
                Required
                valid={watch('subject') && !errors?.subject?.message}
                error={errors?.subject?.message}
            />

            {watch('subject') === 'Other' ? <>
                <Spacer bottom={3}/>
                <Input
                    ref={register({
                        required: {
                            value: true,
                            message: 'Please enter your message'
                        }
                    })}
                    name='other'
                    label='Please tell us more'
                    Required
                    valid={watch('other') && !errors?.other?.message}
                    error={errors?.other?.message}
                    as='textarea'
                    style={{
                        height: 80,
                        fontFamily: 'inherit',
                        lineHeight: 'inherit',
                    }}
                />
            </> : null}

            <Spacer bottom={5}/>
            <Button
                type='primary'
                style={{
                    width: 300
                }}
                disabled={status === 'loading'}
            >
                {status === 'loading' ? (
                    <Spacer top={2}>
                        <Loading width={24}/>
                    </Spacer>
                ) : 'Submit'}
            </Button>

            {typeof status === 'object' ? (
                <Spacer top={5}>
                    {status?.success ? (
                        <Alert.Success
                            content={status?.success}
                        />
                    ) : status?.error ? (
                        <Alert.Error
                            content={status?.error}
                        />
                    ) : null}
                </Spacer>
            ) : null}
        </form>
    )
}

export default function VegasBaby ()
{
    const screenClass = useScreenClass();

    return <>
        <Body>
            <Header>
                <p>
                    Announcing a new strategic partnership!
                </p>

                <Visible xs md>
                    <LearnMore onClick={() => window.scrollTo(0, document.getElementById('content').getBoundingClientRect().top * .95)}>
                        Learn More
                        <Icon.ArrowDown color='white'/>
                    </LearnMore>
                </Visible>
            </Header>

            <Content>
                <Wrapper>
                    <Spacer
                        all={10}
                        style={{
                            background: 'white',
                            margin: 0,
                            borderRadius: Theme.defaultRadius,
                            boxShadow: `0 3px 6px ${Theme.colors.neutrals.gray}33`,
                        }}
                    >
                        <SubTitle style={{textAlign: 'left'}}>
                            To celebrate the DMGgo and dspworkplace partnership, we are offering the following {' '}
                            discounts to new customers through December 2021
                        </SubTitle>

                        <Spacer bottom={10}/>

                        <SubTitleWithIcon>
                            <u>FREE</u> extended trial of dspworkplace
                        </SubTitleWithIcon>

                        <Spacer bottom={5}/>

                        <SubTitleWithIcon>
                            <u>FREE</u> dspworkplace-certified DMGgo implementation services
                        </SubTitleWithIcon>

                        <Spacer bottom={5}/>

                        <SubTitleWithIcon>
                            <u>20% discount on any DMGgo service</u> for both current and new dspworkplace customers
                        </SubTitleWithIcon>

                        <Spacer bottom={10}/>

                        <Button
                            type='primary'
                            size='big'
                            onClick={() => {
                                let i = document.querySelector('input');
                                window.scrollTo(0, (i.getBoundingClientRect().top + document.documentElement.scrollTop) * .9)
                                setTimeout(() => i.focus(), 600);
                            }}
                        >
                            Get in Touch to Learn More
                        </Button>
                    </Spacer>
                </Wrapper>

                <Spacer bottom={20}/>

                <Container id='content'>
                    <Row align={'center'}>
                        <Col lg={8}>
                            <Wrapper>
                            <ThirdTitle>
                                DMGgo and dspworkplace are pleased to announce a new strategic partnership that {' '}
                                combines DMGgo's industry-leading administrative support services with {' '}
                                dspworkplace’s world-class, last-mile focused technology platform.
                            </ThirdTitle>
                            <Spacer bottom={5}/>
                            <ThirdTitle>
                                Top-performing DSPs recognize the critical role best-in-class technology plays  {' '}
                                in running their businesses efficiently--this includes the flexibility to adapt {' '}
                                to the constant cadence of change. We've now combined the most dynamic service  {' '}
                                platform with the most experienced administrative service offering to provide  {' '}
                                you with a business-changing partnership.
                            </ThirdTitle>
                            <Spacer bottom={5}/>
                            <ThirdTitle>
                                This partnership unlocks the following new <br/>
                                and pivotal DMGgo service offerings for the DSP network:
                            </ThirdTitle>
                            <Spacer bottom={5}/>

                            <SubTitleWithIcon>
                                Invoice Reconciliation
                            </SubTitleWithIcon>
                            <Spacer bottom={3}/>

                            <SubTitleWithIcon>
                                Weekend-Only Dispatch
                            </SubTitleWithIcon>
                            <Spacer bottom={3}/>

                            <SubTitleWithIcon>
                                Operational and Profitability Benchmarking
                            </SubTitleWithIcon>
                            <Spacer bottom={7}/>

                            <ThirdTitle>
                                Sign up for a demo and let the DMGgo-dspworkplace partnership bring you a {' '}
                                flexible and dynamic technology platform, back-office administration, and {' '}
                                organizational change management to help your business whenever and wherever you need it.
                            </ThirdTitle>
                            </Wrapper>
                        </Col>
                        <Col lg={4}>
                            <Visible xs md>
                                <Spacer top={10}/>
                            </Visible>

                            <Spacer
                                all={['lg', 'xl', 'xxl'].includes(screenClass) ? 10 : 5}
                                style={{
                                    background: 'white',
                                    borderRadius: Theme.defaultRadius,
                                    boxShadow: `0 3px 6px ${Theme.colors.neutrals.gray}33`,
                                    display: 'flex',
                                    justifyContent: 'center'
                                }}
                            >
                                <ContactForm/>
                            </Spacer>
                        </Col>
                    </Row>
                </Container>

                <Spacer bottom={20}/>
            </Content>
        </Body>
        <Footer/>
    </>
}