import React from "react";
import {NavLink} from "react-router-dom";
import styled from "styled-components";
import {Spacer, Text, Theme} from "@dspworkplace/ui";
import {routes} from "../../Routes";
import {Container} from "../UI";
import {Menu, Tabs} from "../Menus/Main";
import {logo} from '../../assets';

const FooterText = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-family: ${Theme.font.main};
    
    * {
        color: ${Theme.colors.neutrals.medium};
        padding-bottom: 4px;
        display: inline-block;
        margin: 0 4px;
    }
    
    a {
        text-decoration: underline;
    }
`;

const Footer = () => {
    return (
        <Spacer top={5} bottom={10} style={{background: Theme.colors.extra.whiteSmoke}}>
            <Container>
                <Menu style={{height: 'auto', textAlign: 'center'}}>
                    <Tabs>
                        {routes.filter(r => r.useMenu !== false).map((route,i) =>
                            <li key={i}>
                                <NavLink to={route.path} exact style={{paddingLeft: 20, paddingRight: 20}}>{route.name}</NavLink>
                            </li>
                        )}
                    </Tabs>
                </Menu>

                <FooterText>
                    <Text>© 2020 - {new Date().getFullYear()}, DSP Logistics Inc., All Rights Reserved.</Text>
                    <Text><NavLink to='/terms-and-conditions'>Terms and Conditions</NavLink></Text>
                    <Text><NavLink to='/privacy'>Privacy</NavLink></Text>
                </FooterText>

                <Spacer top={10} style={{textAlign: 'center'}}>
                    <img src={logo} alt="dspworkplace" width={100}/>
                </Spacer>
            </Container>
        </Spacer>
    )
}

export default Footer;
