import React, {useEffect} from 'react';
import {Link, Route} from 'react-router-dom';
import Menu from '../../../Components/Menus/Main';
import {routes} from '../../index';
import {Icon, Spacer, Theme, ThirdTitle} from "@dspworkplace/ui";
import {Container} from "../../../Components/UI";
import {useScreenClass, Visible} from "react-grid-system";

const DefaultLayout = ({component: Component, ...rest}) => {
    const screenClass = useScreenClass();
    const isMobile = ['xs', 'sm', 'md'].includes(screenClass);

    useEffect(() => window.scrollTo(0,0), []);

    return (
        <Route {...rest} exact={rest.path === '/'} render={matchProps => (
            <>
                {/*<Link to='/dmggo-partnership' target='_blank' style={{
                    display: 'block',
                    backgroundImage: `linear-gradient(50deg, #f05331 0%, ${Theme.colors.primary} 100%)`,
                    boxShadow: 'inset 0 -12px 8px -8px rgba(0,0,0,0.15)',
                    padding: isMobile ? '20px 8px' : 20,
                    position: isMobile ? 'fixed' : 'static',
                    zIndex: isMobile ? 11 : 0,
                    bottom: 0,
                }}>
                    <Container style={{
                        display: 'flex',
                        flexDirection: isMobile ? 'column' : 'row',
                        alignItems: isMobile ? 'flex-start' : 'center',
                        gap: 8,
                    }}>
                        <small style={{
                            padding: '4px 8px 2px',
                            background: 'rgba(255, 255, 255, .25)',
                            border: '1px solid white',
                            borderRadius: Theme.defaultRadius,
                            font: `bold ${Theme.font.extraSmall.size}/${Theme.font.extraSmall.lineHeight} ${Theme.font.main}`,
                            color: 'white',
                        }}>NEWS</small>
                        <ThirdTitle style={{color: 'white', lineHeight: '19px'}}>
                            DMGgo and dspworkplace announcing a new strategic partnership!
                        </ThirdTitle>
                        <span style={{
                            fontFamily: Theme.font.main,
                            color: 'white',
                            textDecoration: 'underline',
                            marginLeft: isMobile ? 0 : 'auto',
                        }}>
                            Learn more
                            <Icon.ArrowRight size='12px' color={Theme.colors.neutrals.white}/>
                        </span>
                    </Container>
                </Link>*/}
                <Menu routes={routes}/>
                <Component {...matchProps} />

                <Visible xs sm md>
                    <Spacer bottom={36}/>
                </Visible>
            </>
        )}/>
    )
};

export default DefaultLayout;
